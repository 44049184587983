import SimpleAssessmentController from './simple_assessment_controller'
import SmokingHabitsMixin         from '../medical_histories/smoking_habits_mixin'
import { clearInputErrors }       from '../../support/helpers'

export default class extends SmokingHabitsMixin(SimpleAssessmentController) {

  static targets = [
    ...super.targets,
    'conditionInput',
    'fields',
    'section'
  ]

  static classes = [
    'hide'
  ]

  static values = {
    conditions: Array
  }

  conditionsValueChanged() {
    this.showHideSections()
  }

  // ==== Controllers

  // ==== Actions

  selectCondition() {
    this.conditionsValue = this.conditionInputTargets
      .filter((input) => input.checked)
      .map((input) => input.value)

    this.fieldsTarget.classList.toggle('u-hide', this.conditionsValue.length === 0)

    clearInputErrors(this.element)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  sectionConditions(section) {
    return section.dataset.conditions.split(' ')
  }

  showHideSections() {
    this.sectionTargets.forEach((section) => {
      const active = this.conditionsValue.some((condition) => this.sectionConditions(section).includes(condition))
      this.toggleTarget(section, active)
    })
  }

  // ==== Channels

}

import { playSound } from './audio_sound'

const GP_LOBBY_MUTE_ATTR = 'data-gp-lobby-mute'

export function setGpLobbyMute(value) {
  if (value) {
    document.documentElement.setAttribute(GP_LOBBY_MUTE_ATTR, '')
  } else {
    document.documentElement.removeAttribute(GP_LOBBY_MUTE_ATTR)
  }
}

export function getGpLobbyMute() {
  return document.documentElement.hasAttribute(GP_LOBBY_MUTE_ATTR)
}

export function playGpLobbySound(sound) {
  if (getGpLobbyMute()) return

  playSound(sound)
}

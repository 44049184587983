import moment                from 'moment/moment'
import ApplicationController from '../../frontend/support/application_controller'
import I18n                  from '../../frontend/support/i18n'

export const COLORS = [
  'error',
  'success',
  'warning'
]

export default class extends ApplicationController {

  static targets = [
    'timer'
  ]

  static values = {
    color:           String,
    startedAt:       String,
    orangeThreshold: Number,
    redThreshold:    Number
  }

  static classes = [
    ...COLORS
  ]

  connect() {
    this.updateWaitTime = this.updateWaitTime.bind(this)
    this.intervalId     = setInterval(this.updateWaitTime, 1000)
    this.updateWaitTime()
  }

  disconnect() {
    clearInterval(this.intervalId)
  }

  // ==== Controllers

  // ==== Actions

  updateWaitTime() {
    const timeElapsed = this.duration.asSeconds()
    this.#updateWaitTimeColor(timeElapsed)

    this.timerTarget.textContent = this.formattedTime
  }

  // ==== Getters

  get startedAt() {
    return moment(this.startedAtValue)
  }

  get duration() {
    return moment.duration(moment().diff(this.startedAt))
  }

  get formattedTime() {
    return I18n.l('time.formats.time_elapsed_leading', this.duration.asMilliseconds())
  }

  get colorClasses() {
    return COLORS.map((color) => this.#getColorClass(color))
  }

  // ==== Setters

  // ==== Private

  #updateWaitTimeColor(timeElapsed) {
    const timerClassList = this.timerTarget.classList
    timerClassList.remove(...this.colorClasses)

    let color = 'error'
    if (timeElapsed < this.orangeThresholdValue) {
      color = 'success'
    } else if (timeElapsed < this.redThresholdValue) {
      color = 'warning'
    }
    timerClassList.add(this.#getColorClass(color))
    this.colorValue = color

    this.dispatch('tick', {
      bubbles: true,
      prefix:  'timer',
      detail:  { timeElapsed, color }
    })
  }

  #getColorClass(color) {
    return this[`${color}Class`]
  }

  // ==== Channels

}

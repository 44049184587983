import ApplicationController from '../../frontend/support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'container'
  ]

  static values = {}

  static classes = [
    'hide'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggle() {
    this.containerTarget.classList.toggle(this.hideClass)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}

import { get }               from '@rails/request.js'
import camelCase             from 'lodash/camelCase'
import ApplicationController from '../../support/application_controller'
import $fq                   from '../../support/fake_query'
import BmiCalculator         from '../../support/bmi_calculator'
import EscalationMixin       from '../../support/escalation_mixin'
import I18n                  from '../../support/i18n'
import WizardMixin           from '../../support/wizard_mixin'
import { animationFrame }    from '../../support/helpers'

export default class extends WizardMixin(EscalationMixin(ApplicationController)) {

  static targets = [
    ...super.targets,
    'autoSave',
    'bmi',
    'breastFeedingReasonsNoneOfTheAbove',
    'contraceptiveManagementPatientStatus',
    'contraceptiveManagementType',
    'dehydrationSymptomsNoneOfTheAbove',
    'exposureType',
    'exposureWithin72Hours',
    'form',
    'gastroenteritisSymptomsNoneOfTheAbove',
    'goutSymptomsNoneOfTheAbove',
    'height',
    'hivConfirmationTestResultInconclusive',
    'hivConfirmationTestResultReactive',
    'hivTestResult',
    'howJointAffectedAnkleNoneOfTheAbove',
    'howJointAffectedBigToeNoneOfTheAbove',
    'howJointAffectedOtherFootNoneOfTheAbove',
    'jointsAffectedAnkle',
    'jointsAffectedBigToe',
    'jointsAffectedFoot',
    'jointsAffectedNone',
    'jointsAffectedNoneOfTheAbove',
    'jointsAffectedOtherFoot',
    'medicalConditionsNoneInverse',
    'medicationNoneInverse',
    'noPregnancyTestReason',
    'otherSymptomsNoneOfTheAbove',
    'patientDiabetic',
    'precipitatingFactorsNoneOfTheAbove',
    'pregnancyStatus',
    'pregnancyStatusInputNone',
    'pregnancyStatusNone',
    'pregnancyStatusNoneInverse',
    'pregnancyStatusNoneOfTheAbove',
    'pregnancyStatusPatientIsBreastFeeding',
    'pregnancyTestResult',
    'prepSymptomsNoneOfTheAbove',
    'recentProcedure',
    'submit',
    'symptomsCommonColdNoneOfTheAbove',
    'symptomsDuration',
    'symptomsMaleStiNoneOfTheAbove',
    'symptomsStiNoneOfTheAbove',
    'symptomsStiNoneOfTheAbove',
    'symptomsTonsillitisNoneOfTheAbove',
    'takingProgesterone',
    'throatObservationsNoneOfTheAbove',
    'urinarySymptomsNoneOfTheAbove',
    'urineTestStripNo',
    'urineTestStripYes',
    'weight'
  ]

  static classes = [
    'hide'
  ]

  static outlets = [
    'popover'
  ]

  initialize() {
    this.checkForEscalation = this.checkForEscalation.bind(this)
  }

  connect() {
    BmiCalculator(this)
    // HACK: popovers are cooked!
    window.addEventListener('resize', this.updatePopovers, { passive: true })
    this.scrollbars?.addEventListener('scroll', this.updatePopovers, { passive: true })

    this.observer = new MutationObserver(this.checkForEscalation)
    this.observer.observe(this.element, { childList: true, subtree: true })

    this.element.addEventListener('input', this.checkForEscalation)

    this.checkForEscalation()
  }

  disconnect() {
    // HACK: popovers are cooked!
    window.removeEventListener('resize', this.updatePopovers)
    this.scrollbars?.removeEventListener('scroll', this.updatePopovers)

    this.element.removeEventListener('input', this.checkForEscalation)
    this.observer?.disconnect()
  }

  updatePopovers = () => {
    // HACK: popovers are cooked!
    this.popoverOutlets.forEach((popover) => popover.update())
  }

  async checkForEscalation() {
    // HACK: this controller really shouldn't run outside of the assessment form
    if (!this.hasSubmitTarget) return

    await animationFrame()
    this.updatePopovers()

    const hasEscalation = this.outOfRangeGlyphTargets.some((glyph) => glyph.checkVisibility())

    if (hasEscalation) {
      this.submitTarget.value = 'escalation'
      this.submitTarget.textContent = I18n.t('app.start_comprehensive')
    } else {
      this.submitTarget.value = ''
      this.submitTarget.textContent = I18n.t('app.join_videomed_express_queue')
    }
  }

  // ==== Controllers

  // ==== Actions

  toggleCondition(event) {
    this.#conditions().forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleMedicationNoneInverse(event) {
    this.toggleNoneInverseSelections({
      input:   event.currentTarget,
      targets: this.medicationNoneInverseTargets
    })
  }

  toggleMedicalConditionsNoneInverse(event) {
    this.toggleNoneInverseSelections({
      input:   event.currentTarget,
      targets: this.medicalConditionsNoneInverseTargets
    })
  }

  togglePatientDiabetic(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.patientDiabeticTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.patientDiabeticTarget
      })
    }
  }

  toggleHivTestTaken(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.hivTestResultTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.hivTestResultTarget
      })
    }
  }

  toggleHivTestResult(event) {
    [
      { value: 'reactive', target: this.hivConfirmationTestResultReactiveTarget },
      { value: 'inconclusive', target: this.hivConfirmationTestResultInconclusiveTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  // === Acute Gastro Enteritis

  toggleDehydrationSymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.dehydrationSymptomsNoneOfTheAboveTargets,
      dispatchInput: true
    })
  }

  toggleGastroenteritisSymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.gastroenteritisSymptomsNoneOfTheAboveTargets,
      dispatchInput: true
    })
    this.#toggleSymptomsDuration(
      this.gastroenteritisSymptomsNoneOfTheAboveTargets
    )
  }

  // === Acute Gout Episode

  toggleGoutSymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.goutSymptomsNoneOfTheAboveTargets,
      dispatchInput: true
    })
    this.#toggleSymptomsDuration(
      this.goutSymptomsNoneOfTheAboveTargets
    )
  }

  togglePrecipitatingFactorsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.precipitatingFactorsNoneOfTheAboveTargets,
      dispatchInput: true
    })
  }

  toggleJointsAffected({ currentTarget: { checked }, params: { listItem } }) {
    const targetKey = camelCase(`joints_affected_${listItem}_target`)

    if (this[camelCase(`has_${targetKey}`)]) {
      this[targetKey].classList.toggle(this.hideClass, !checked)
    }
  }

  toggleJointsAffectedNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.jointsAffectedNoneOfTheAboveTargets,
      dispatchInput: true
    })
  }

  toggleHowJointAffectedBigToeNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.howJointAffectedBigToeNoneOfTheAboveTargets,
      dispatchInput: false
    })
  }

  toggleHowJointAffectedOtherFootNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.howJointAffectedOtherFootNoneOfTheAboveTargets,
      dispatchInput: false
    })
  }

  toggleHowJointAffectedAnkleNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.howJointAffectedAnkleNoneOfTheAboveTargets,
      dispatchInput: false
    })
  }

  // === Pre-Exposure Prophylaxis

  togglePrepSymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.prepSymptomsNoneOfTheAboveTargets,
      dispatchInput: false
    })
  }

  // === Post-Exposure Prophylaxis

  toggleExposureSexualAssault(event) {
    if (event.currentTarget.value === 'no') {
      this.showRadioButtonMoreDetails({
        target: this.exposureWithin72HoursTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.exposureWithin72HoursTarget
      })
    }
  }

  toggleExposureWithin72Hours(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.exposureTypeTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.exposureTypeTarget
      })
    }
  }

  // === Common Cold

  toggleSymptomsCommonColdNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.symptomsCommonColdNoneOfTheAboveTargets,
      dispatchInput: true
    })
    this.#toggleSymptomsDuration(
      this.symptomsCommonColdNoneOfTheAboveTargets
    )
  }

  // === Sexually Transmitted Infection

  toggleSymptomsStiNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.symptomsStiNoneOfTheAboveTargets,
      dispatchInput: true
    })
    this.#toggleSymptomsDuration(
      this.symptomsStiNoneOfTheAboveTargets
    )
  }

  // === Tonsillitis

  toggleSymptomsTonsillitisNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.symptomsTonsillitisNoneOfTheAboveTargets,
      dispatchInput: false
    })
    this.#toggleSymptomsDuration(
      this.symptomsTonsillitisNoneOfTheAboveTargets
    )
  }

  toggleThroatObservationsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.throatObservationsNoneOfTheAboveTargets,
      dispatchInput: false
    })
  }

  // === Urinary Tract Infection

  toggleRecentProcedure(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.recentProcedureTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.recentProcedureTarget
      })
    }
  }

  toggleUrineTestStrip(event) {
    [
      { value: 'no', target: this.urineTestStripNoTarget },
      { value: 'yes', target: this.urineTestStripYesTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleUrinarySymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.urinarySymptomsNoneOfTheAboveTargets,
      dispatchInput: true
    })
    this.#toggleSymptomsDuration(
      this.urinarySymptomsNoneOfTheAboveTargets,
      this.otherSymptomsNoneOfTheAboveTargets
    )
  }

  toggleOtherSymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.otherSymptomsNoneOfTheAboveTargets,
      dispatchInput: true
    })
    this.#toggleSymptomsDuration(
      this.urinarySymptomsNoneOfTheAboveTargets,
      this.otherSymptomsNoneOfTheAboveTargets
    )
  }

  // === Contraceptive Management

  togglePatientCurrentlyBreastFeeding(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.takingProgesteroneTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.takingProgesteroneTarget
      })
    }
  }

  toggleContraceptiveManagementType(event) {
    if (!this.hasContraceptiveManagementPatientStatusTarget) { return }

    if (event.currentTarget.value === 'repeat') {
      this.showRadioButtonMoreDetails({
        target: this.contraceptiveManagementPatientStatusTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.contraceptiveManagementPatientStatusTarget
      })
    }
  }

  toggleBreastFeedingReasonsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.breastFeedingReasonsNoneOfTheAboveTargets,
      noneValue:     'none',
      dispatchInput: true
    })
  }

  togglePregnancyStatus({ currentTarget: { checked }, params: { listItem } }) {
    const targetKey = camelCase(`pregnancy_status_${listItem}_target`)

    if (this[camelCase(`has_${targetKey}`)]) {
      this[targetKey].classList.toggle(this.hideClass, !checked)
    }
  }

  togglePregnancyStatusNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.pregnancyStatusNoneOfTheAboveTargets,
      dispatchInput: true,
      noneTarget:    this.pregnancyStatusInputNoneTarget
    })
  }

  togglePregnancyTest(event) {
    [
      { value: 'no', target: this.noPregnancyTestReasonTarget },
      { value: 'yes', target: this.pregnancyTestResultTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  // === Escalation

  async checkRange(event) {
    const input     = event.currentTarget
    const queryData = new FormData()

    const {
      submitAllChecked,
      submitCheckedTargets,
      frame,
      framePerKey,
      escalate,
      autoSave
    } = event.params

    if (autoSave) this.autoSaveForm()
    if (!escalate) return

    if (input.dataset.bloodPressure) {
      queryData.append(this.bloodPressureDiastolicTarget.name, this.bloodPressureDiastolicTarget.value)
      queryData.append(this.bloodPressureSystolicTarget.name, this.bloodPressureSystolicTarget.value)
    } else if (submitAllChecked) {
      queryData.append(input.name, '')
      this.element
        .querySelectorAll(`input[type=checkbox][name="${input.name}"]:checked`)
        .forEach((target) => queryData.append(target.name, target.value))
    } else if (submitCheckedTargets) {
      this[`${submitCheckedTargets}Targets`]
        .forEach((target) => {
          this.element
            .querySelectorAll(`input[name="${target.name}"]`)
            .forEach((targetInput) => {
              if (targetInput.type === 'checkbox' && !targetInput.checked) return

              queryData.append(targetInput.name, targetInput.value)
            })
        })
    } else {
      let value = input.value

      if (input.type === 'checkbox' && !input.checked) {
        value = `${value}_unchecked`
      }

      queryData.append(input.name, value)
    }

    if (framePerKey) queryData.append('frame_per_key', '1')
    if (frame) queryData.append('frame_param', frame)

    const response = await get(
      `${this.rangeCheckUrlValue}?${new URLSearchParams(queryData).toString()}`,
      { responseKind: 'turbo-stream' }
    )

    if (response.ok) {
      this.outOfRangePopoverTargets.forEach((elPopover) => {
        if (!$fq(elPopover).hasClass('u-hide')) {
          $fq(elPopover).hide()
        }
      })

      this.outOfRangeGlyphTargets.forEach((elGlyph) => {
        $fq(elGlyph).changeGlyph('#exclamation-circle')
      })

      this.#updateBloodPressureGlyph({ element: input, show: response.statusCode === 200 })
    }
  }

  submitForm() {
    this.submitTarget.click()
  }

  autoSaveForm() {
    this.formTarget.requestSubmit(this.autoSaveTarget)
  }

  toggleOutOfRangePopover({ params: { frame } }) {
    this.toggleOutOfRangePopoverInternal({ targetStr: frame })
    this.updatePopovers()
  }

  // ==== Getters

  // HACK: popovers are cooked!
  get scrollbars() {
    return this.element.closest('.m-scrollbars')
  }

  // ==== Setters

  // ==== Private

  #conditions() {
    const conditions = []

    if (this.hasContraceptiveManagementTypeTarget) {
      conditions.push({ value: 'contraceptive_management', target: this.contraceptiveManagementTypeTarget })
    }

    return conditions
  }

  #updateBloodPressureGlyph({ element, show } = {}) {
    if (!element.dataset.bloodPressure) return

    const glyph = this.outOfRangeGlyphBloodPressureDiastolicTarget

    if (show) {
      this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle-outline', targetStr: 'BloodPressureDiastolic' })
      $fq(glyph).show()
    } else {
      $fq(glyph).hide()
    }
  }

  /**
   * Toggles symptoms duration off if all none_of_the_above are checked in the given targets
   * THIS TAKES A LIST OF LISTS! Each list is a list of ALL checkboxes for each symptom checkbox collection
   * NOTE: this only works if all the none_of_the_above's values are in fact :none_of_the_above
   * @param  {...HTMLElement[]} targetGroups list of lists of checkboxes
   */
  #toggleSymptomsDuration(...targetGroups) {
    const allNoneChecked = targetGroups.every((targets) => {
      const noneCheckbox = targets.find((target) => target.value === 'none_of_the_above')
      if (!noneCheckbox) throw new Error(`missing none_of_the_above checkbox: ${targets[0]?.name || 'unknown'}`)
      return noneCheckbox.checked || targets.every((target) => !target.checked)
    })

    this.symptomsDurationTarget.classList.toggle(this.hideClass, allNoneChecked)
  }

}

import ApplicationController from '../../support/application_controller'
import WizardMixin           from '../../support/wizard_mixin'
import {
  disableElements,
  enableElements
} from '../../support/helpers'

export default class extends WizardMixin(ApplicationController) {

  static targets = [
    'autoSave',
    'form',
    'ingredient',
    'ingredientInput',
    'medicationInput',
    'prescription',
    'referralLetter'
  ]

  static classes = [
    'hide'
  ]

  togglePrescription({ currentTarget: { checked }, params: { prescription } }) {
    const prescriptionTarget = this.prescriptionTargets
      .find((target) => target.dataset.prescription === prescription)
    if (!prescriptionTarget) return

    prescriptionTarget.classList.toggle(this.hideClass, !checked)
    disableElements(prescriptionTarget, !checked)

    if (checked) {
      this.#autoSelect(prescriptionTarget, this.ingredientInputTargets, (input) => {
        this.toggleIngredient({ currentTarget: input, params: { prescription } })
      })
    }
  }

  toggleIngredient({ currentTarget: { value }, params: { prescription } }) {
    this.ingredientTargets
      .filter((target) => target.dataset.prescription === prescription)
      .forEach((ingredientTarget) => {
        if (ingredientTarget.dataset.ingredient === value) {
          ingredientTarget.classList.remove(this.hideClass)
          enableElements(ingredientTarget)
          this.#autoSelect(ingredientTarget, this.medicationInputTargets)
        } else {
          ingredientTarget.classList.add(this.hideClass)
          disableElements(ingredientTarget)
        }
      })
  }

  toggleReferralLetter(event) {
    if (event.currentTarget.value === 'pathology_referral_letter') {
      this.showRadioButtonMoreDetails({
        target: this.referralLetterTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.referralLetterTarget
      })
    }
  }

  autoSaveICD10Code(event) {
    event.currentTarget.form.requestSubmit()
  }

  autoSave() {
    if (!this.hasAutoSaveTarget) return

    this.formTarget.requestSubmit(this.autoSaveTarget)
  }

  // ===== Private

  #autoSelect(wrapper, inputTargets, block = () => {}) {
    const inputs = inputTargets.filter((target) => wrapper.contains(target))
    if (inputs.length === 1) {
      const [input] = inputs
      input.checked = true
      block(input)
    }
  }

}

import ApplicationController from '../support/application_controller'
import { setGpLobbyMute }     from '../support/gp_lobby_sound'

export default class extends ApplicationController {

  static values = { active: Boolean }

  connect() {
    setGpLobbyMute(!this.activeValue)
  }

  disconnect() {
    setGpLobbyMute(false)
  }
  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

}

import camelCase from 'lodash/camelCase'
import WizardController from '../../../wizard_controller'

export default class extends WizardController {

  static targets = [
    ...super.targets,
    'drinksDetails',
    'nestedTextInput',
    'nestedTextWrapper',
    'fatherStatusDeceasedDetails',
    'fatherStatusLivingDetails',
    'gravidityDetails',
    'itemDetails',
    'motherStatusDeceasedDetails',
    'motherStatusLivingDetails',
    'radioButtonDetails',
    'sectionDetailsNoneOfTheAbove',
    'sectionDetailsNoneOfTheAboveCheckbox',
    'sectionNoneOfTheAbove',
    'siblingDetails',
    'siblingStatusDeceasedDetails',
    'siblingStatusLivingDetails',
    'singleCheckBoxDetails',
    'stoppedDrinkingDetails',
    'toggleShowFor'
  ]

  static values = {
    conditionNone: String,
    sectionItem:   String
  }

  // ==== Actions

  // === Checkboxes

  toggleSectionNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.sectionNoneOfTheAboveTargets,
      noneValue:     this.conditionNoneValue,
      dispatchInput: true
    })
  }

  toggleSectionItemDetails(event) {
    const detailsSection = event.currentTarget.dataset.detailsSection
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.itemDetailsTargets.find((target) => target.dataset.detailsSection === detailsSection)
    })
  }

  toggleSectionDetailsNoneOfTheAbove({ currentTarget: { checked }, params: { targetType } }) {
    if (!checked) return

    if (targetType === 'none') {
      const notNoneTargets = this.sectionDetailsNoneOfTheAboveTargets
      notNoneTargets.forEach((target) => {
        this.toggleChecked(target)
      })
    } else {
      const noneInput   = this.sectionDetailsNoneOfTheAboveCheckboxTarget
      this.toggleChecked(noneInput)
    }
  }

  toggleSingleCheckBoxDetails(event) {
    this.toggleNoneInverseSelection({
      input:  event.currentTarget,
      target: this.singleCheckBoxDetailsTarget
    })
  }

  // === Radio buttons show more details

  toggleRadioButtonDetails(event) {
    const detailsSection = event.currentTarget.dataset.detailsSection
    const expandOn = event.currentTarget.dataset.expandOn
    if (event.currentTarget.value === expandOn) {
      this.showRadioButtonMoreDetails({
        target: this.radioButtonDetailsTargets.find((target) => target.dataset.detailsSection === detailsSection)
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.radioButtonDetailsTargets.find((target) => target.dataset.detailsSection === detailsSection)
      })
    }
  }

  // Alcohol use

  toggleDrinkingStatusDetails(event) {
    [
      { value: 'stopped_drinking', target: this.stoppedDrinkingDetailsTarget },
      { value: 'drinks', target: this.drinksDetailsTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  // Family history

  toggleFamilyConditionsSelections({ currentTarget: { checked }, params: { condition } }) {
    const targetKey = camelCase(`${condition}_target`)

    if (this[camelCase(`has_${targetKey}`)]) {
      this[targetKey].classList.toggle(this.hideClass, !checked)
    }
  }

  toggleFamilyConditionsSelectionDetails({ currentTarget: { checked }, params: { familyMember } }) {
    const targetKey = camelCase(`${familyMember}_target`)

    if (this[camelCase(`has_${targetKey}`)]) {
      this[targetKey].classList.toggle(this.hideClass, !checked)
    }
  }

  toggleMotherStatusDetails(event) {
    [
      { value: 'living', target: this.motherStatusLivingDetailsTarget },
      { value: 'deceased', target: this.motherStatusDeceasedDetailsTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleFatherStatusDetails(event) {
    [
      { value: 'living', target: this.fatherStatusLivingDetailsTarget },
      { value: 'deceased', target: this.fatherStatusDeceasedDetailsTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleSiblingStatusDetails(event) {
    [
      { value: 'living', first_input_value: 'current_age', targets: this.siblingStatusLivingDetailsTargets },
      { value: 'deceased', first_input_value: 'age_at_death', targets: this.siblingStatusDeceasedDetailsTargets }
    ].forEach((obj) => {
      const selId  = event.currentTarget.name.replace(/sibling_status\]/g, `sibling_status_${obj.first_input_value}]`)
      const target = obj.targets.find((_target) => _target.querySelector(`input[name="${selId}"]`))

      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target
        })
      }
    })
  }

  toggleSiblingDetails(event) {
    if (event.currentTarget.value === 'patient_has_siblings') {
      this.showRadioButtonMoreDetails({
        target: this.siblingDetailsTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.siblingDetailsTarget
      })
    }
  }

  // === Nested inputs

  addNestedTextInputItem() {
    this.addNestedForm({
      list:      this.nestedTextInputTargets,
      inputType: "input[type='radio'], input[type='text']",
      wrapper:   this.nestedTextWrapperTarget
    })

    // Hide all expanded siblings details inputs
    const livingSelector   = "div[data-consultation-note--dischem-life-assessment--step--short-medical-report-target='siblingStatusLivingDetails']"
    const deceasedSelector = "div[data-consultation-note--dischem-life-assessment--step--short-medical-report-target='siblingStatusDeceasedDetails']"
    this.nestedTextInputTargets.at(-1).querySelectorAll(`${livingSelector}, ${deceasedSelector}`).forEach((target) => {
      this.hideTarget(target)
    })
  }

  removeNestedTextInputItem(event) {
    this.removeNestedForm({
      button:  event.currentTarget,
      wrapper: this.nestedTextWrapperTarget,
      list:    this.nestedTextInputTargets
    })
  }

  // Womens health

  toggleGravidityDetails(event) {
    const element = event.currentTarget

    if (element.value > 0) {
      this.showTarget(this.gravidityDetailsTarget)
    } else {
      this.hideTarget(this.gravidityDetailsTarget)
    }
  }

  // ==== Templates

  toggleSymptom(event) {
    const attributeGroupName         = event.currentTarget.dataset.attributeGroupName
    const attributeValue             = event.currentTarget.value
    const attributeGroupElements     = this.toggleShowForTargets.filter((target) => target.dataset.attributeGroup === attributeGroupName)
    const attributeGroupShowElements = this.toggleShowForTargets.filter(
      (target) => target.dataset.templateShowFor === `${attributeGroupName}_${attributeValue}`
    )

    this.hideTargets(attributeGroupElements)
    this.showTargets(attributeGroupShowElements)
  }

  hideTargets(targets) {
    targets.forEach((target) => this.hideTarget(target))
  }

  showTargets(targets) {
    targets.forEach((target) => this.showTarget(target))
  }

  // ==== Shared

  toggleChecked(target) {
    const wasChecked  = target.checked
    target.checked = false

    if (wasChecked) {
      target.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

}

import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'anchor',
    'popover'
  ]

  static values = {
    autoOpen:        Boolean,
    avoidScreenEdge: Boolean,
    direction:       String,
    position:        String
  }

  initialize() {}

  connect() {
    if (this.autoOpenValue) this.show()
    if (this.isOpen) this.update()
  }

  // ==== Controllers

  // ==== Actions

  show() {
    this.popoverTarget.showPopover()
  }

  hide() {
    this.popoverTarget.hidePopover()
  }

  toggle() {
    this.popoverTarget.togglePopover()
  }

  beforeToggle({ oldState, newState }) {
    this.element.dataset.toggling     = true
    this.element.dataset.togglingFrom = oldState
    this.element.dataset.togglingTo   = newState
  }

  afterToggle({ newState }) {
    delete this.element.dataset.toggling
    delete this.element.dataset.togglingFrom
    delete this.element.dataset.togglingTo

    if (newState === 'open') this.update()
  }

  updateIfOpen() {
    if (this.isOpen) this.update()
  }

  // ==== Getters

  get anchor() {
    if (this.hasAnchorTarget) return this.anchorTarget

    return this.element
  }

  get anchorRect() {
    return this.anchor.getBoundingClientRect()
  }

  get popoverRect() {
    return this.popoverTarget.getBoundingClientRect()
  }

  get isOpen() {
    return this.popoverTarget.matches(':popover-open')
  }

  get isPositionBottom() {
    if (this.isBottomOfViewport) return true
    if (this.isTopOfViewport) return false
    return this.positionValue?.includes('bottom')
  }

  get isPositionRight() {
    if (this.isLeftOfViewport) return true
    if (this.isRightOfViewport) return false
    return this.positionValue?.includes('right')
  }

  get isDirectionLeft() {
    if (this.isLeftOfViewport) return false
    if (this.isRightOfViewport) return true
    return this.directionValue?.includes('left')
  }

  get isDirectionUp() {
    if (this.isBottomOfViewport) return true
    if (this.isTopOfViewport) return false
    return this.directionValue?.includes('up')
  }

  get potentialPopoverBottom() {
    return this.anchorRect.bottom + this.popoverRect.height
  }

  get potentialPopoverTop() {
    return this.anchorRect.top - this.popoverRect.height
  }

  get potentialPopoverLeft() {
    return this.anchorRect.left - this.popoverRect.width
  }

  get potentialPopoverRight() {
    return this.anchorRect.right + this.popoverRect.width
  }

  get isLeftOfViewport() {
    if (!this.avoidScreenEdgeValue) return false

    return this.potentialPopoverLeft < 0
  }

  get isRightOfViewport() {
    if (!this.avoidScreenEdgeValue) return false

    return this.potentialPopoverRight > window.visualViewport.width
  }

  get isBottomOfViewport() {
    if (!this.avoidScreenEdgeValue) return false

    return this.potentialPopoverBottom > window.visualViewport.height
  }

  get isTopOfViewport() {
    if (!this.avoidScreenEdgeValue) return false

    return this.potentialPopoverTop < 0
  }

  // ==== Setters

  // ==== Private

  update() {
    const anchorRect = this.anchorRect
    const popoverRect = this.popoverRect

    let top  = anchorRect.top
    let left = anchorRect.left

    if (this.isPositionBottom) top += anchorRect.height
    if (this.isPositionRight) left += anchorRect.width
    if (this.isDirectionUp) top -= popoverRect.height
    if (this.isDirectionLeft) left -= popoverRect.width

    this.popoverTarget.style.setProperty('--c-popover-position-top', `${top}px`)
    this.popoverTarget.style.setProperty('--c-popover-position-left', `${left}px`)
  }

  // ==== Channels

}

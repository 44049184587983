import Rails                 from '@rails/ujs'
import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'action',
    'responseBody'
  ]

  static values = {
    disableAllClicks: Boolean
  }

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  open(event) {
    const element = event.currentTarget
    const url     = element.dataset.url

    Rails.ajax({
      url,
      type:       'GET',
      data:       null,
      dataType:   'script',
      beforeSend: (xhr, options) => {
        $fq(this.responseBodyTarget).innerHTML('')
        $fq(element).addClass('-loaderActive')

        return (xhr, options)
      },
      success:    (_response, _status, xhr) => {
        $fq(this.responseBodyTarget).innerHTML(xhr.response)
      },
      error:      (_response, _status, _xhr) => { },
      complete:   (_xhr, _status) => {
        $fq(element).removeClass('-loaderActive')
      }
    })
  }

  close() {
    $fq(this.responseBodyTarget).innerHTML('')
  }

  // ==== Getters

  get disableAllClicks() {
    return this.disableAllClicksValue || true
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
